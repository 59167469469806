import { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import VerifyOtpNumber from './verifyOtpNumber';
import PhoneInput from 'react-phone-input-2'
import startsWith from 'lodash.startswith';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-phone-input-2/lib/bootstrap.css'
import Reaptcha from 'reaptcha';

export default function SendOtpNumber({ typeRef }) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const username = slug.split('?')[0];
  const { state } = useLocation();
  const [isOtp, setIsOtp] = useState(false)
  const [gState, setState] = useState({})
  const [phone, setPhone] = useState({})
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  useEffect(() => {
    if (!state?.upline) navigate(`/invite/${username}`)
    getGeoInfo();
  }, [phone]);

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
          country_tld: (data?.country_tld?.replace(".", ""))
        });
      })
  };


  const handleOnChange = (value, data) => {
    setPhone({
      country_id: `+${data.dialCode}`,
      phone_number: value.slice(data.dialCode.length)
    })
  }

  const verifyCaptcha = (e) => {
    setToken(e)
    setRefreshReCaptcha()
  }

  const sendOtp = async () => {
    if(!phone?.phone_number) {
      notify("Please enter your phone number!")
      return
    }

    if(!token) {
      notify("Please complete the captcha below!")
      return
    }

    const { data } = await axios.post(`${process.env.REACT_APP_API}/referal/otp`, {
      ...phone,
    }, {
      headers: {
        'Ip-Address': gState?.ip,
        'Ip-Country': gState?.countryName,
        "Authorization": token
      }
    })

    if (data?.statusCode === 409) {
      notify(data.message)
      return
    }

    setIsOtp(true)
  }

  const notify = async (msg) => {
    await toast.dismiss()
    await toast.error(msg, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <div className='pb-3 mt-16'>
      {
        !isOtp ? <div>
          <div className='heading text-left text-gray-400 pb-5'>
            <p className="text-3xl font-semibold">Enter your mobile no.</p>
          </div>

          <PhoneInput
            className="w-full"
            country={`${gState?.country_tld}`}
            value={gState.phone}
            onChange={handleOnChange}
            searchNotFound={true}
            isValid={(inputNumber, country, countries) => {
              return countries.some((country) => {
                return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
              });
            }}
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true
            }}
          />

          <div className='mt-5'>
            <Reaptcha
              sitekey={"6LfZGIAmAAAAAOXplwG4GVs_vK1kPaeWtR9cDrKf"}
              onVerify={verifyCaptcha}
              refreshReCaptcha={refreshReCaptcha}
            />
          </div>

          <button
            onClick={() => sendOtp()}
            className="w-full bg-gradient-to-r from-[#62CDCB] via-[#53B1D4] to-[#4599DB] hover:opacity-95 text-white font-bold py-2 px-4 rounded-lg mt-6"
          >
            Get OTP
          </button>
        </div> : <VerifyOtpNumber upline={state?.upline} phones={phone} prof={state.prof} typeRef={typeRef} />
      }
    </div>
  );
}