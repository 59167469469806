import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';


export default function SuccessReferrals({ typeRef }) {
  // if(typeRef=="group") window.location.assign("https://youappgroup.page.link/group/GROUPNME")

  // if(typeRef=="profile") window.location.assign("https://youappreferral.page.link/friend")

  const navigate = useNavigate();
  // let { username } = useParams();
  const [username, setUsername] = useState()

  const { state } = useLocation();

  useEffect(() => {
    setUsername(state.username)
    if (!state?.prof) navigate(`/invite/${username}`)
    if (!state?.provider) navigate(`/invite/${username}`)
  }, []);




  return (
    <div>
      {!state?.prof?.thumbnail ? (
        <svg className="text-gray-200 w-28 h-28 dark:text-gray-700 mx-auto" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"></path></svg>
      ) : (
        <img className="w-28 h-28 rounded-full mx-auto" src={state?.prof?.thumbnail} alt='profile picture' />
      )}

      <div className='heading text-center text-gray-400 pb-5'>
        <p className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#D5BE88] via-[#F8FAE5] to-[#D5BE88] pb-4">You are almost there!</p>
        {
          ["profile", "group"].includes(typeRef) && (
            <>
              <span className='text-white'>Download YouApp and login with your
                <span className='ml-1 font-semibold'>{state?.provider}</span>
                {typeRef == "profile" && (
                  <span> and <span className='font-semibold'>{username}</span> will be added as your contact!</span>
                )}

                {typeRef == "group" && (<span> to join {state.prof.name} group!</span>)}
              </span>
            </>
          )
        }

        {typeRef == "event" && (<span> Download YouApp to chat with other attendees of <span className='font-semibold'>{username}</span> </span>)}
      </div>

      <div className="flex items-center justify-center gap-4 sm:space-x-4 md:mt-[25%]">
        <a className='m-0' href="https://play.google.com/store/apps/details?id=com.youapp.you_app" target='_blank'> <img src='/assets/logo_google_play.png' /> </a>
        <a className='m-0' href="https://apps.apple.com/us/app/youapp-ai/id6444595490" target='_blank'> <img src='/assets/logo_app_store.png' /> </a>
      </div>
    </div>
  );
}
