import { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useParams, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import axios from "axios";

export default function ReferralFriend({ typeRef }) {
  const { slug } = useParams();
  const username = slug.split('?')[0];
  // const username = window.location.href.split("/").pop();
  const navigate = useNavigate();
  const [refEndPoint] = useState({
    profile: "referal/find/profile",
    group: "referal/find/group",
    event: "referal/find/event",
    service: "referal/find/service"
  });
  const [isAgree, setAgree] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSignIn, setLoadingSignIn] = useState(false);
  const [profile, setProfile] = useState();
  const [isProfile, setIsProfile] = useState(false);
  const clientId = `${process.env.REACT_APP_APPLE_CLIENT_ID}`;
  const redirectURI = `${window.location.origin}`;
  const scope = "name email";
  const state = `${process.env.REACT_APP_DOMAIN}`;

  useEffect(() => {
    window.AppleID.auth.init({
      clientId,
      scope,
      redirectURI,
      state,
      usePopup: true,
      response_type: "query",
    });

    const slug = window.location.href.split("/").pop();
    document.title = `YouApp${!!slug ? " - " + slug : " Invite"}`

    document.addEventListener("AppleIDSignInOnSuccess", (event) => {
      appleVerify(event?.detail?.authorization?.id_token);
    });

    document.addEventListener("AppleIDSignInOnFailure", (event) => {
      // console.log("Error ", event);
    });
  }, [window, profile]);

  const appleSignIn = () => {
    window.AppleID.auth.signIn();
  };

  const appleVerify = (token) => {
    var appleIDSignIn = jwt_decode(token);
    socialRef("apple", appleIDSignIn?.sub);
    console.log("appleIDSignIn", appleIDSignIn);
  };

  const googleSignIn = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      socialRef("google", userInfo.sub);
    },
    onError: () => {
      notify("We are unable to process your request at this time.");
    },
  });

  const fbSignIn = (response) => {
    if (response.status === "unknown") return;
    socialRef("facebook", response?.userID);
    console.log("facebookSignIn", response);

  };

  const socialRef = async (provider, sub) => {
    setLoadingSignIn(true);
    const uplineId = profile?._id;
    if (!uplineId) return;
    try {
      const res = await axios.post(`${process.env.REACT_APP_API}/referal/validate/social`, {
        social_provider: provider,
        social_uid: sub,
        uplineId: profile?._id,
        typeRef: typeRef,
      });
      if (res?.statusCode === 409) {
        setLoadingSignIn(false);
        notify(res.message);
        return;
      }
      setLoadingSignIn(false);
      navigate(`/invite/${username}/verified`, {
        state: {
          username,
          prof: profile,
          provider: provider,
        }
      });
    } catch (error) {
      setLoadingSignIn(false);
      notify("We are unable to process your request at this time.");
    }
  };

  const notify = async (
    msg = "Do you agree with YouApp's Terms of Service and Privacy Policy?"
  ) => {
    await toast.dismiss();
    await toast.error(msg, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const fetchReferralUpline = async () => {
    setLoading(true);
    let slug = window.location.href.split("/").pop()
    slug = slug.split('?')[0]
    const { data } = await axios.post(
      `${process.env.REACT_APP_API}/${refEndPoint[typeRef]}`,
      {
        q: slug,
      }
    );
    if (data?.statusCode === 409 || data?.statusCode === 404) {
      setIsProfile(false);
      setLoading(false);
      return;
    }

    setProfile(data.data);
    setIsProfile(true);
    setLoading(false);
  };

  useEffect(() => {
    if (!profile) fetchReferralUpline();
  }, [profile]);

  return (
    <div className="pb-3">
      {isLoadingSignIn && (
        <div
          role="status"
          className="fixed -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 w-full h-full bg-gray-400/40"
        >
          <div className="flex flex-col justify-center my-auto h-full items-center">
            <svg
              aria-hidden="true"
              className="w-8 h-8 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {isLoading && (
        <div role="status" className="shadow animate-pulse mt-2">
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-3 mx-auto"></div>
          <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-6 mx-auto"></div>
          <svg
            className="text-gray-200 w-28 h-28 dark:text-gray-700 mx-auto"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}

      {!isProfile && !isLoading && (
        <>
          <div className="heading text-center pb-5">
            <p className="text-xl font-bold bg-clip text-white">
              Sorry, <span className="capitalize">{typeRef}</span> link does not exist
            </p>
            <p className="mt-3">
              <span className="text-md font-semibold text-gray-400">
                Don’t miss out on the fun! Be the real YOU, explore business
                networking, casual dates and experiences with like-minded
                people!!
              </span>
            </p>
          </div>

          <img
            class="object-contain mx-auto h-46 w-full sm:w-4/4"
            src="/assets/frame1.png"
            alt="logo youapp"
          />
          <p className="text-xl font-bold bg-clip text-white py-4">
            Download YouApp today!
          </p>
          <div class="flex flex-wrap gap-4">
            <a
              href="https://apps.apple.com/us/app/youapp-ai/id6444595490"
              target="_blank"
            >
              <img
                class="object-contain mx-auto h-auto w-full sm:w-3/4"
                src="/assets/logo_app_store.png"
                alt="logo youapp"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.youapp.you_app"
              target="_blank"
            >
              <img
                class="object-contain mx-auto h-auto w-full sm:w-3/4"
                src="/assets/logo_google_play.png"
                alt="logo youapp"
              />
            </a>
          </div>
        </>
      )}

      {isProfile && !isLoading && (
        <div>
          {typeRef === "profile" && (
            <div className="heading text-center text-gray-400 pb-5">
              <p className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#D5BE88] via-[#F8FAE5] to-[#D5BE88]">
                Welcome!
              </p>
              <span>You have been invited to join YouApp by</span>
            </div>
          )}

          <div className="flex flex-col items-center justify-center gap-3 pb-4 ">
            {!profile?.thumbnail ? (
              <svg
                className="text-gray-200 w-28 h-28 dark:text-gray-700 mx-auto"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <img
                className="w-28 h-28 rounded-full mx-auto"
                src={profile?.thumbnail}
                alt="profile picture"
              />
            )}

            <p className="text-white items-center font-semibold">
              {profile?.name} <br />
              {typeRef === "group" && (
                <span className="font-normal text-gray-400">
                  {profile?.members ?? 0} Members
                </span>
              )}
            </p>
          </div>

          {typeRef === "profile" && (
            <p className="text-gray-400	text-center">
              Invites you to join YouApp and chat. <br /> Once you join, you
              will be added as a friend.
            </p>
          )}
        
          {typeRef === "group" &&  (
            <p className="text-gray-400	text-center">
              You will be added into the {profile?.name} Group <br /> once you
              join YouApp.
            </p>
          )}
        
          {typeRef === "event" &&  (
            <p className="text-gray-400	text-center">
              See the attendee's profiles, find shared accommodations, post event parties, etc.
            </p>
          )}

          <div className="flex justify-center pt-6 pb-3">
            <div className="flex items-center h-5">
              <input
                id="helper-checkbox"
                aria-describedby="helper-checkbox-text"
                type="checkbox"
                value={isAgree}
                onChange={() => setAgree(!isAgree)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            <div className="ml-2 text-sm">
              <label
                htmlFor="helper-checkbox"
                className="font-medium text-gray-400 dark:text-gray-300"
              >
                I have read and agree to YouApp's{" "}
                <a
                  href="https://youapp.ai/terms-of-service/"
                  className="text-white font-semibold"
                  target="_blank"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://youapp.ai/privacy-policy/"
                  className="text-white font-semibold"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </label>
            </div>
          </div>

          <ul className="my-2 space-y-3">
            <li data-btn-target="sign-in-facebook">
              <FacebookLogin
                appId={process.env.REACT_APP_FB_APP_ID}
                xfbml={true}
                cookie={true}
                callback={fbSignIn}
                isMobile={false}
                state={username}
                render={(renderProps) => (
                  <button
                    onClick={() =>
                      !isAgree ? notify() : renderProps.onClick()
                    }
                    className="flex items-center w-full p-3 text-base font-semibold text-white rounded-lg bg-[#a8a8a80f] hover:bg-[#FFFFFF2D] group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                  >
                    <svg
                      width="30"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient
                          id="paint0_linear_1738_329785"
                          x1="14"
                          y1="23.7732"
                          x2="14"
                          y2="3.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#0062E0" />
                          <stop offset="1" stopColor="#19AFFF" />
                        </linearGradient>
                      </defs>
                      <path
                        d="M12.25 24.3833C7.29167 23.5083 3.5 19.1917 3.5 14C3.5 8.225 8.225 3.5 14 3.5C19.775 3.5 24.5 8.225 24.5 14C24.5 19.1917 20.7083 23.5083 15.75 24.3833L15.1667 23.9167H12.8333L12.25 24.3833Z"
                        fill="url(#paint0_linear_1738_329785)"
                      />
                      <path
                        d="M18.0833 16.9167L18.55 14H15.75V11.9584C15.75 11.1417 16.0417 10.5 17.325 10.5H18.6667V7.81671C17.9083 7.70004 17.0917 7.58337 16.3333 7.58337C13.9417 7.58337 12.25 9.04171 12.25 11.6667V14H9.625V16.9167H12.25V24.325C12.8333 24.4417 13.4167 24.5 14 24.5C14.5833 24.5 15.1667 24.4417 15.75 24.325V16.9167H18.0833Z"
                        fill="white"
                      />
                    </svg>
                    <span className=" ml-3 whitespace-nowrap">
                      Login with Facebook
                    </span>
                  </button>
                )}
              />
            </li>

            <li data-btn-target="sign-in-apple-id">
              <button
                onClick={() => (!isAgree ? notify() : appleSignIn())}
                className="flex items-center w-full p-3 text-base font-semibold text-white rounded-lg bg-[#a8a8a80f] hover:bg-[#FFFFFF2D] group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
              >
                <svg
                  width="30"
                  height="19"
                  viewBox="0 0 16 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8002 17.28C11.8202 18.23 10.7502 18.08 9.72016 17.63C8.63016 17.17 7.63016 17.15 6.48016 17.63C5.04016 18.25 4.28016 18.07 3.42016 17.28C-1.45984 12.25 -0.739836 4.59 4.80016 4.31C6.15016 4.38 7.09016 5.05 7.88016 5.11C9.06016 4.87 10.1902 4.18 11.4502 4.27C12.9602 4.39 14.1002 4.99 14.8502 6.07C11.7302 7.94 12.4702 12.05 15.3302 13.2C14.7602 14.7 14.0202 16.19 12.7902 17.29L12.8002 17.28ZM7.78016 4.25C7.63016 2.02 9.44016 0.18 11.5202 0C11.8102 2.58 9.18016 4.5 7.78016 4.25Z"
                    fill="white"
                  />
                </svg>
                <span className=" ml-3 whitespace-nowrap">
                  Login with Apple ID{" "}
                </span>
              </button>
            </li>

            <li data-btn-target="sign-in-google">
              <button
                onClick={() => (!isAgree ? notify() : googleSignIn())}
                className="flex items-center w-full p-3 text-base font-semibold text-white rounded-lg bg-[#a8a8a80f] hover:bg-[#FFFFFF2D] group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
              >
                <svg
                  width="30"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_601_173520"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                  >
                    <path
                      d="M22.5 10H12.25V14.25H18.15C17.6 16.95 15.3 18.5 12.25 18.5C8.65 18.5 5.75 15.6 5.75 12C5.75 8.4 8.65 5.5 12.25 5.5C13.8 5.5 15.2 6.05 16.3 6.95L19.5 3.75C17.55 2.05 15.05 1 12.25 1C6.15 1 1.25 5.9 1.25 12C1.25 18.1 6.15 23 12.25 23C17.75 23 22.75 19 22.75 12C22.75 11.35 22.65 10.65 22.5 10Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask0_601_173520)">
                    {" "}
                    <path
                      d="M0.25 18.5V5.5L8.75 12L0.25 18.5Z"
                      fill="#FBBC05"
                    />{" "}
                  </g>
                  <mask
                    id="mask1_601_173520"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                  >
                    <path
                      d="M22.5 10H12.25V14.25H18.15C17.6 16.95 15.3 18.5 12.25 18.5C8.65 18.5 5.75 15.6 5.75 12C5.75 8.4 8.65 5.5 12.25 5.5C13.8 5.5 15.2 6.05 16.3 6.95L19.5 3.75C17.55 2.05 15.05 1 12.25 1C6.15 1 1.25 5.9 1.25 12C1.25 18.1 6.15 23 12.25 23C17.75 23 22.75 19 22.75 12C22.75 11.35 22.65 10.65 22.5 10Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask1_601_173520)">
                    {" "}
                    <path
                      d="M0.25 5.5L8.75 12L12.25 8.95L24.25 7V0H0.25V5.5Z"
                      fill="#EA4335"
                    />{" "}
                  </g>
                  <mask
                    id="mask2_601_173520"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                  >
                    <path
                      d="M22.5 10H12.25V14.25H18.15C17.6 16.95 15.3 18.5 12.25 18.5C8.65 18.5 5.75 15.6 5.75 12C5.75 8.4 8.65 5.5 12.25 5.5C13.8 5.5 15.2 6.05 16.3 6.95L19.5 3.75C17.55 2.05 15.05 1 12.25 1C6.15 1 1.25 5.9 1.25 12C1.25 18.1 6.15 23 12.25 23C17.75 23 22.75 19 22.75 12C22.75 11.35 22.65 10.65 22.5 10Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask2_601_173520)">
                    {" "}
                    <path
                      d="M0.25 18.5L15.25 7L19.2 7.5L24.25 0V24H0.25V18.5Z"
                      fill="#34A853"
                    />{" "}
                  </g>
                  <mask
                    id="mask3_601_173520"
                    maskUnits="userSpaceOnUse"
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                  >
                    <path
                      d="M22.5 10H12.25V14.25H18.15C17.6 16.95 15.3 18.5 12.25 18.5C8.65 18.5 5.75 15.6 5.75 12C5.75 8.4 8.65 5.5 12.25 5.5C13.8 5.5 15.2 6.05 16.3 6.95L19.5 3.75C17.55 2.05 15.05 1 12.25 1C6.15 1 1.25 5.9 1.25 12C1.25 18.1 6.15 23 12.25 23C17.75 23 22.75 19 22.75 12C22.75 11.35 22.65 10.65 22.5 10Z"
                      fill="white"
                    />
                  </mask>
                  <g mask="url(#mask3_601_173520)">
                    {" "}
                    <path
                      d="M24.25 24L8.75 12L6.75 10.5L24.25 5.5V24Z"
                      fill="#4285F4"
                    />{" "}
                  </g>
                </svg>
                <span className="ml-3 whitespace-nowrap">
                  Login with Google{" "}
                </span>
              </button>
            </li>

            <li data-btn-target="sign-in-otp">
              <button
                onClick={() =>
                  !isAgree
                    ? notify()
                    : navigate(`verify-account`, {
                      state: { upline: profile?._id, prof: profile },
                    })
                }
                className="flex items-center w-full p-3 text-base font-semibold text-white rounded-lg bg-[#a8a8a80f] hover:bg-[#FFFFFF2D] group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
              >
                <svg
                  width="30"
                  height="22"
                  viewBox="0 0 18 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      id="paint0_linear_2050_348998"
                      x1="-1.8"
                      y1="17.4706"
                      x2="22.0431"
                      y2="12.0284"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.0237305" stopColor="#94783E" />
                      <stop offset="0.216904" stopColor="#F3EDA6" />
                      <stop offset="0.329505" stopColor="#F8FAE5" />
                      <stop offset="0.486109" stopColor="#FFE2BE" />
                      <stop offset="0.723574" stopColor="#D5BE88" />
                      <stop offset="0.809185" stopColor="#F8FAE5" />
                      <stop offset="0.902849" stopColor="#D5BE88" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2050_348998"
                      x1="6.3"
                      y1="5.32353"
                      x2="9.02375"
                      y2="2.21504"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.0237305" stopColor="#94783E" />
                      <stop offset="0.216904" stopColor="#F3EDA6" />
                      <stop offset="0.329505" stopColor="#F8FAE5" />
                      <stop offset="0.486109" stopColor="#FFE2BE" />
                      <stop offset="0.723574" stopColor="#D5BE88" />
                      <stop offset="0.809185" stopColor="#F8FAE5" />
                      <stop offset="0.902849" stopColor="#D5BE88" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_2050_348998"
                      x1="6.9077"
                      y1="17.5529"
                      x2="11.4021"
                      y2="16.2706"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.0237305" stopColor="#94783E" />
                      <stop offset="0.216904" stopColor="#F3EDA6" />
                      <stop offset="0.329505" stopColor="#F8FAE5" />
                      <stop offset="0.486109" stopColor="#FFE2BE" />
                      <stop offset="0.723574" stopColor="#D5BE88" />
                      <stop offset="0.809185" stopColor="#F8FAE5" />
                      <stop offset="0.902849" stopColor="#D5BE88" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M17 6V16C17 20 16 21 12 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H12C16 1 17 2 17 6Z"
                    stroke="url(#paint0_linear_2050_348998)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11 4.5H7"
                    stroke="url(#paint1_linear_2050_348998)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.0002 18.1C9.85624 18.1 10.5502 17.406 10.5502 16.55C10.5502 15.694 9.85624 15 9.0002 15C8.14415 15 7.4502 15.694 7.4502 16.55C7.4502 17.406 8.14415 18.1 9.0002 18.1Z"
                    stroke="url(#paint2_linear_2050_348998)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className=" ml-3 whitespace-nowrap">
                  Login with Phone Number
                </span>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
